export default [
    {
        path: '/document-form-view/form',
        name: 'document-form-view',
        component: () => import('@/views/helper/form/TableForm.vue'),
        meta: {
            action: 'index',
            resource: 'module_documents_and_forms.forms.view_form',
        }
    },
    {
        path: '/document-form-admin/edit-forms',
        name: 'document-form-admin-edit',
        component: () => import('@/views/admin/forms/ViewForms.vue'),
        meta: {
          action: 'index',
          resource: 'module_documents_and_forms.forms.edit_form',
        }
      },
]