import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import dashboardModule from './routes/dashboard'
import patientModule from './routes/patient' 
import paymentModule from './routes/payment'
import moduleModule from './routes/module'
import crmModule from './routes/crm'
import dataAndReportModule from './routes/data-and-report'
import documentAndFormsModule from './routes/document-and-forms'
import helperModule from './routes/helper'
import webSettingsModule from './routes/web-settings'
import pages from './routes/pages'
/* import others from './routes/others'
import uiElements from './routes/ui-elements/index'
import apps from './routes/apps'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables' */


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-analytics' } },
    ...dashboardModule,
    ...patientModule,
    ...paymentModule,
    ...moduleModule,
    ...crmModule,
    ...dataAndReportModule,
    ...documentAndFormsModule,
    ...helperModule, 
    ...webSettingsModule,
    ...pages,
    /* ...others,
    ...apps,
    ...chartsMaps,
    ...formsTable,
    ...uiElements, */
   
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {

  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    
    // this.$pusher.config.auth.headers.Authorization = `Bearer ${localStorage.getItem(
    //   "accessToken"
    // )}`;

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    //cambiar cuando tenga roles
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
