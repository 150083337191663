export default [
  {
    path: '/patients',
    name: 'patients',
    component: () => import('@/views/patients/patients-list/TablePatients.vue'),
    meta: {
      action: 'index',
      resource: 'module_patient.patient',
    }
  },
  {
    path: '/patients/add',
    name: 'patients-add',
    component: () => import('@/views/patients/patients-list/TablePatients.vue'),
    meta: {
      action: 'index',
      resource: 'module_patient.patient',
      isAddPatient: true
    }
  },
  {
    path: '/patients/view/:id',
    name: 'patients-view',
    component: () => import('@/views/patients/patients-view/PatientsView.vue'),
    meta: {
      action: 'show',
      resource: 'module_patient.patient',
    }
  },
  {
    path: '/resource-schedule',
    name: 'resource-schedule',
    component: () => import('@/views/appointment/appointment-review/IndexAppointment.vue'),
    meta: {
      action: 'index',
      resource: 'module_patient.resource_schedule',
    }
  },
  // {
  //   path: '/resource-schedule',
  //   name: 'resource-schedule',
  //   component: () => import('@/views/appointment/appointment-review/TableAppointment.vue'),
  //   meta: {
  //     action: 'index',
  //     resource: 'module_patient.resource_schedule',
  //   }
  // },
  {
    path: '/recipe-patient/:recipeId',
    name: 'recipe-patient',
    component: () => import('@/views/appointment/appointment-recipes/RecipePatient.vue'),
    meta: {
      action: 'appointment_recipe',
      resource: 'module_patient.resource_schedule',
    }
  },
  {
    path: '/recipe-insurance-patient/payment',
    name: 'recipe-insurance-payment',
    component: () => import('@/views/appointment/appointment-recipes/RecipeInsurancePayment.vue'),
    meta: {
      action: 'appointment_recipe',
      resource: 'module_patient.resource_schedule',
    }
  },
  {
    path: '/office-visit',
    name: 'office-visit',
    component: () => import('@/views/office-visit/office-visit-review/IndexOfficeVisit.vue'),
    meta: {
      action: 'index',
      resource: 'module_patient.office_visit',
    }
  },
  // {
  //   path: '/office-visit',
  //   name: 'office-visit',
  //   component: () => import('@/views/office-visit/office-visit-review/TableOfficeVisit.vue'),
  //   meta: {
  //     action: 'index',
  //     resource: 'module_patient.office_visit',
  //   }
  // },
  {
    path: '/office-visit-nurse/:id',
    name: 'office-visit-view-nurse',
    component: () => import('@/views/office-visit/office-visit-review/NurseProcedure.vue'),
    meta: {
      action: 'nurse_procedure',
      resource: 'module_patient.office_visit',
    }
  },
  {
    path: '/office-visit-oma/:id',
    name: 'office-visit-view-oma',
    component: () => import('@/views/office-visit/office-visit-review/IndexOmaProcedure.vue'),
    meta: {
      action: 'oma_procedure',
      resource: 'module_patient.office_visit',
    }
  },
  // {
  //   path: '/office-visit-oma/:id',
  //   name: 'office-visit-view-oma',
  //   component: () => import('@/views/office-visit/office-visit-review/OmaProcedure.vue'),
  //   meta: {
  //     action: 'oma_procedure',
  //     resource: 'module_patient.office_visit',
  //   }
  // },
  {
    path: '/office-visit-recipe/:id',
    name: 'office-visit-recipe',
    component: () => import('@/views/office-visit/recipe/RecipeInfo.vue'),
    meta: {
      action: 'confirm_procedures',
      resource: 'module_patient.office_visit',
    }
  },
  {
    path: '/office-visit-recipe-oma/:id',
    name: 'office-visit-recipe-oma',
    component: () => import('@/views/office-visit/recipe/RecipeInfoOma.vue'),
    meta: {
      action: 'order_view',
      resource: 'module_patient.office_visit',
    }
  },
]