export default [
  {
    path: '/crm/board',
    name: 'crm-board',
    component: () => import('@/views/crm/boards/BoardPanel.vue'),
    meta: {
      action: 'index',
      resource: 'module_360crm.boards',
    }
  },
  {
    path: '/cdm/admin/labels',
    name: 'crm-admin-labels',
    component: () => import('@/views/admin/crm/labels/TableLabel.vue'),
    meta: {
      action: 'index',
      resource: 'module_360crm.crm_admin.labels',
    }
  },
  {
    path: '/cdm/admin/card-status',
    name: 'crm-admin-card-status',
    component: () => import('@/views/admin/crm/card-status/TableCardStatus.vue'),
    meta: {
      action: 'index',
      resource: 'module_360crm.crm_admin.card_status',
    }
  },
  {
    path: '/cdm/admin/boards',
    name: 'crm-admin-boards',
    component: () => import('@/views/admin/crm/boards/TableBoard.vue'),
    meta: {
      action: 'index',
      resource: 'module_360crm.crm_admin.board',
    }
  },
  {
    path: '/cdm/admin/columns',
    name: 'crm-admin-columns',
    component: () => import('@/views/admin/crm/columns/TableColumn.vue'),
    meta: {
      action: 'index',
      resource: 'module_360crm.crm_admin.column',
    }
  }
]