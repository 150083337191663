export default [
    {
        path: '/help-center/tutorial',
        name: 'help-center-tutorial',
        component: () => import('@/views/helper/tutorial/Faq.vue'),
        meta: {
            action: 'index',
            resource: 'module_help_center.learning_center.360tutorials',
        }
    },
    {
        path: '/help-center/information',
        name: 'help-center-information',
        component: () => import('@/views/resource/information/Faq.vue'),
        meta: {
            action: 'index',
            resource: 'module_help_center.learning_center.tutorials',
        }
    },
    {
        path: '/help-center/types-tutorials',
        name: 'help-center-types-tutorials',
        component: () => import('@/views/admin/tutorial/categories/TableCategories.vue'),
        meta: {
            action: 'index',
            resource: 'module_help_center.learning_center.add_tutorial.add_categories',
        }
    },
    {
        path: '/help-center/tutorials',
        name: 'help-center-create-tutorials',
        component: () => import('@/views/admin/tutorial/tutorial/TableTutorial.vue'),
        meta: {
            action: 'index',
            resource: 'module_help_center.learning_center.add_tutorial.create',
        }
    },
    {
        path: '/help-center/tutorial/create',
        name: 'help-center-tutorial-create',
        component: () => import('@/views/admin/tutorial/tutorial/TutorialAdd.vue'),
        meta: {
            action: 'store',
            resource: 'module_help_center.learning_center.add_tutorial.create',
        }
    },
    {
        path: '/help-center/tutorial/edit/:id',
        name: 'help-center-tutorial-edit',
        component: () => import('@/views/admin/tutorial/tutorial/TutorialEdit.vue'),
        meta: {
            action: 'update',
            resource: 'module_help_center.learning_center.add_tutorial.create',
        }
    },
    {
        path: '/help-center/goal-score',
        name: 'help-center-goal-score',
        component: () => import('@/views/resource/score/ScoreUser.vue'),
        meta: {
            action: 'index',
            resource: 'module_help_center.goal_score',
        }
    },
]