export default [
    {
        path: '/payment/resource-schedule',
        name: 'payment-resource-schedule',
        component: () => import('@/views/appointment/appointment-review/TableAppointment.vue'),
        meta: {
            action: 'index',
            resource: 'module_payment.invoice_list',
        }
    },
    {
        path: '/payment/cash-account',
        name: 'payment-cash-account',
        component: () => import('@/views/cash-account/CashAccount.vue'),
        meta: {
            action: 'index',
            resource: 'module_payment.daily_closing.run_closing',
        }
    },
    {
        path: '/payment/cash-closing',
        name: 'payment-cash-closing',
        component: () => import('@/views/cash-account/cash-closing/FormCashClosing.vue'),
        meta: {
            action: 'index',
            resource: 'module_payment.daily_closing.run_closing',
        }
    },
    {
        path: '/payment/cash-daily-boxes',
        name: 'payment-cash-daily-boxes',
        component: () => import('@/views/cash-account/cash-daily/TableCashDaily.vue'),
        meta: {
            action: 'index',
            resource: 'module_payment.daily_closing.daily_boxes',
        }
    },
    {
        path: '/payment/cash-history/:id?',
        name: 'payment-cash-history',
        component: () => import('@/views/cash-account/cash-history/TableCash.vue'),
        meta: {
            action: 'index',
            resource: 'module_payment.daily_closing.daily_boxes',
        }
    },
    {
        path: '/payment/refund',
        name: 'payment-refund',
        component: () => import('@/views/admin/type-consult/refund/TableRefund.vue'),
        meta: {
          action: 'index',
          resource: 'module_payment.refunds',
        }
      }
]