export default {
    namespaced: true,
    state: {
        userData: {} || null,
        token: null,
        facility: null,
        facilityOptions: [],
        ability: [],
    },
    mutations: {
        setUserData(state, payload) {
            state.userData = payload
        },
        setToken(state, payload) {
            state.token = payload
        },
        setAbility(state, payload) {
            state.ability = payload
        },
        setFacility(state, payload) {
            state.facility = payload
        },
        setFacilityOptions(state, payload) {
            state.facilityOptions = payload
        }
    },

}