export default [
    {
        path: '/module/promotion',
        name: 'module-promotion',
        component: () => import('@/views/admin/type-consult/promotion/TablePromotion.vue'),
        meta: {
            action: 'index',
            resource: 'module_modules.type_of_modules.promotions',
        }
    },
    {
        path: '/module/survey',
        name: 'module-survey',
        component: () => import('@/views/admin/survey/TableSurvey.vue'),
        meta: {
            action: 'index',
            resource: 'module_modules.type_of_modules.survey',
        }
    },
    {
        path: '/module/survey-results/:id',
        name: 'module-survey-results',
        component: () => import('@/views/admin/survey/result/TableSurveyResult.vue'),
        meta: {
            action: 'show_responses',
            resource: 'module_modules.type_of_modules.survey',
        }
    },
    {
        path: '/module-inventory/item-status',
        name: 'module-inventory-item-status',
        component: () => import('@/views/admin/inventory/admin/item-status/TableItemStatus.vue'),
        meta: {
            action: 'index',
            resource: 'module_modules.type_of_modules.inventory.status',
        },
    },
    {
        path: '/module-inventory/item-category',
        name: 'module-inventory-item-category',
        component: () => import('@/views/admin/inventory/admin/item-category/TableItemCategory.vue'),
        meta: {
            action: 'index',
            resource: 'module_modules.type_of_modules.inventory.category',
        },
    },
    {
        path: '/module-inventory/item-presentation',
        name: 'module-inventory-item-presentation',
        component: () => import('@/views/admin/inventory/admin/item-presentation/TableItemPresentation.vue'),
        meta: {
            action: 'index',
            resource: 'module_modules.type_of_modules.inventory.presentation',
        },
    },
    {
        path: '/module-inventory/item',
        name: 'module-inventory-item',
        component: () => import('@/views/admin/inventory/admin/item/TableItem.vue'),
        meta: {
            action: 'index',
            resource: 'module_modules.type_of_modules.inventory.item',
        },
    },
    {
        path: '/module-inventory/stock',
        name: 'module-inventory-stock',
        component: () => import('@/views/admin/inventory/admin/stock/TableStock.vue'),
        meta: {
            action: 'index',
            resource: 'module_modules.type_of_modules.inventory.item_stock',
        },
    },
    {
        path: '/module-inventory/order',
        name: 'module-inventory-order',
        component: () => import('@/views/admin/inventory/admin/order/TableOrder.vue'),
        meta: {
            action: 'index',
            resource: 'module_modules.type_of_modules.inventory.order',
        },
    },
    {
        path: '/module-inventory/stock-facility',
        name: 'module-inventory-stock-facility',
        component: () => import('@/views/admin/inventory/admin/stock-facility/TableStockFacility.vue'),
        meta: {
            action: 'index',
            resource: 'module_modules.type_of_modules.inventory.clinic_stock',
        },
    },
    {
        path: '/module/memberships',
        name: 'module-memberships',
        component: () => import('@/views/admin/memberships/TableMembership.vue'),
        meta: {
            action: 'index',
            resource: 'module_modules.type_of_modules.memberships.membership',
        }
    },
    {
        path: '/module/memberships/code',
        name: 'module-memberships-code',
        component: () => import('@/views/admin/memberships/code/TableCodeMembership.vue'),
        meta: {
            action: 'index',
            resource: 'module_modules.type_of_modules.memberships.codes',
        }
    },
    {
        path: '/module/memberships/users',
        name: 'module-memberships-users',
        component: () => import('@/views/admin/memberships/users/TableUserMembership.vue'),
        meta: {
            action: 'index',
            resource: 'module_modules.type_of_modules.memberships.users',
        }
    },
    {
        path: '/module/memberships/lotes',
        name: 'module-memberships-lotes',
        component: () => import('@/views/admin/memberships/lote/TableLoteMembership.vue'),
        meta: {
            action: 'index',
            resource: 'module_modules.type_of_modules.memberships.lotes',
        }
    },
    {
        path: '/module/memberships/types',
        name: 'module-memberships-types',
        component: () => import('@/views/admin/memberships/type/TableTypeMembership.vue'),
        meta: {
            action: 'index',
            resource: 'module_modules.type_of_modules.memberships.types',
        }
    },
]
