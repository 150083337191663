export default [
    {
        path: '/web-settings/users',
        name: 'web-settings-users',
        component: () => import('@/views/admin/users/users-list/UserListV2.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_user.type_of_user',
        }
    },
    {
        path: '/web-settings/users/view/:id',
        name: 'web-settings-users-view',
        component: () => import('@/views/admin/users/users-view/UsersView.vue'),
        meta: {
            action: 'show',
            resource: 'module_system_settings.settings.admin_user.type_of_user',
        }
    },
    {
        path: '/web-settings/users/edit/:id',
        name: 'web-settings-users-edit',
        component: () => import('@/views/admin/users/users-edit/UsersEdit.vue'),
        meta: {
            action: 'update',
            resource: 'module_system_settings.settings.admin_user.type_of_user',
        }
    },
    {
        path: '/web-settings/roles',
        name: 'web-settings-roles',
        component: () => import('@/views/admin/roles-permissions/RolesPermissions.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_user.user_roles',
        }
    },
    {
        path: '/web-settings/roles-permissions/:id',
        name: 'web-settings-roles-permissions',
        component: () => import('@/views/admin/roles-permissions/Permissions.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_user.user_roles',
        }
    },
    {
        path: '/web-settings/promoter',
        name: 'web-settings-promoter',
        component: () => import('@/views/admin/type-consult/promoters/TablePromoters.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_user.promoters',
        }
    },
    {
        path: '/web-settings/goals',
        name: 'web-settings-goals',
        component: () => import('@/views/admin/goals/TableGoal.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_user.goals',
        }
    },
    {
        path: '/web-settings/types-goals',
        name: 'web-settings-types-goals',
        component: () => import('@/views/admin/goals/types/TableTypeGoal.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_user.goal_types',
        }
    },
    {
        path: '/web-settings/quantifications',
        name: 'web-settings-quantifications',
        component: () => import('@/views/admin/goals/quantifications/TableQuantification.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_user.goal_quantifications',
        }
    },
    {
        path: '/web-settings-goal-score',
        name: 'web-settings-goal-score',
        component: () => import('@/views/admin/goals/score/FormScore.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_user.goal_score',
        }
    },
    {
        path: '/web-settings/facilities',
        name: 'web-settings-facilities',
        component: () => import('@/views/admin/facilities/TableFacilities.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_facility.facilities',
        }
    },
    {
        path: '/web-settings/facilities/view/:id',
        name: 'web-settings-facilities-view',
        component: () => import('@/views/admin/facilities/FacilityView.vue'),
        meta: {
            action: 'show',
            resource: 'module_system_settings.settings.admin_facility.facilities',
        }
    },
    {
        path: '/web-settings/facilities/edit/:id',
        name: 'web-settings-facilities-edit',
        component: () => import('@/views/admin/facilities/FacilityEdit.vue'),
        meta: {
            action: 'update',
            resource: 'module_system_settings.settings.admin_facility.facilities',
        }
    },
    {
        path: '/web-settings/regions',
        name: 'web-settings-regions',
        component: () => import('@/views/admin/regions/TableRegions.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_facility.regions',
        }
    },
    {
        path: '/web-settings/regions/view/:id',
        name: 'web-settings-regions-view',
        component: () => import('@/views/admin/regions/regions-teams/RegionView.vue'),
        meta: {
            action: 'show',
            resource: 'module_system_settings.settings.admin_facility.regions',
        }
    },
    {
        path: '/web-settings/states-cities',
        name: 'web-settings-states-cities',
        component: () => import('@/views/admin/states-cities/StateCity.vue'),
        children: [
            {
                path: 'states',
                name: 'web-settings-states',
                component: () => import('@/views/admin/states-cities/state/TableStates.vue'),
                meta: {
                    action: 'index',
                    resource: 'module_system_settings.settings.admin_facility.states',
                }
            },
            {
                path: 'cities',
                name: 'web-settings-cities',
                component: () => import('@/views/admin/states-cities/cities/TableCities.vue'),
                meta: {
                    action: 'index',
                    resource: 'module_system_settings.settings.admin_facility.cities',
                }
            }
        ],
    },
    {
        path: '/web-settings/visit',
        name: 'web-settings-visit',
        component: () => import('@/views/admin/type-consult/visit/TypeVisit.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_visit.types_of_visit',
        }
    },
    {
        path: '/web-settings/claim-status',
        name: 'web-settings-claim-status',
        component: () => import('@/views/admin/type-consult/claim-status/ClaimStatus.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_visit.claim_status',
        }
    },
    {
        path: '/web-settings/consult',
        name: 'web-settings-consult',
        component: () => import('@/views/admin/type-consult/consult/TypeConsult.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_visit.visit_reason',
        }
    },
    {
        path: '/web-settings/visit-status',
        name: 'web-settings-visit-status',
        component: () => import('@/views/admin/type-consult/visit-status/TableVisitStatus.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_visit.visit_status',
        }
    },
    {
        path: '/web-settings/review-system',
        name: 'web-settings-review-system',
        component: () => import('@/views/admin/type-consult/review-system/TableReviewSystem.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_subject.review_system',
        }
    },
    {
        path: '/web-settings/review-system-category',
        name: 'web-settings-category-review-system',
        component: () => import('@/views/admin/type-consult/review-system-category/TableReviewSystemCategory.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_subject.review_system_category',
        }
    },
    {
        path: '/web-settings/treatment',
        name: 'web-settings-treatment',
        component: () => import('@/views/admin/type-consult/treatment/TableTreatment.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_treatments.treatments',
        }
    },
    {
        path: '/web-settings/diagnoses',
        name: 'web-settings-diagnoses',
        component: () => import('@/views/admin/type-consult/diagnoses/TableDiagnoses.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_diagnoses.diagnoses',
        }
    },
    {
        path: '/web-settings/medication',
        name: 'web-settings-medication',
        component: () => import('@/views/admin/type-consult/medication/TableMedication.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_form.medications',
        }
    },
    {
        path: '/web-settings/allergies',
        name: 'web-settings-allergies',
        component: () => import('@/views/admin/type-consult/allergies/TableAllergies.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_form.allergies',
        }
    },
    {
        path: '/web-settings/ailment',
        name: 'web-settings-ailment',
        component: () => import('@/views/admin/type-consult/ailment/TableAilment.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_form.ailments',
        }
    },
    {
        path: '/web-settings/test',
        name: 'web-settings-test',
        component: () => import('@/views/admin/type-consult/test/TableTest.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.in_house.ih_tests',
        }
    },
    {
        path: '/web-settings/procedures',
        name: 'web-settings-procedures',
        component: () => import('@/views/admin/type-consult/procedures/TableProcedures.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.in_house.ih_procedures',
        }
    },
    {
        path: '/web-settings/category_lab',
        name: 'web-settings-category_lab',
        component: () => import('@/views/admin/type-consult/category_lab/TableCategoryLab.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.laboratory.categories',
        }
    },
    {
        path: '/web-settings/laboratory',
        name: 'web-settings-laboratory',
        component: () => import('@/views/admin/type-consult/laboratory/TableLaboratory.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.laboratory.laboratories',
        }
    },
    {
        path: '/web-settings/diagnostic-image',
        name: 'web-settings-diagnostic-image',
        component: () => import('@/views/admin/type-consult/diagnostic-image/TableDiagnosticImage.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.imaging.diagnostic_imaging',
        }
    },
    {
        path: '/web-settings/diagnostic-imaging-category',
        name: 'web-settings-diagnostic-imaging-category',
        component: () => import('@/views/admin/type-consult/diagnostic-image-category/TableDiagnosticImageCategory.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.imaging.categories',
        }
    },
    {
        path: '/web-settings/consult-price',
        name: 'web-settings-consult-price',
        component: () => import('@/views/admin/type-consult/consult-price/TableConsultPrice.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.consult_price',
        }
    },
    {
        path: '/web-settings/used-cupons',
        name: 'web-settings-used-cupons',
        component: () => import('@/views/admin/type-consult/used-cupons/TableUsedCupons.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.used_cupons',
        }
    },
    {
        path: '/web-settings/template',
        name: 'web-settings-template',
        component: () => import('@/views/admin/configuration/AdminPreviewTemplate.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.web_settings.template',
        }
    },
    /* log sessions */
    {
        path: '/web-settings/fail-sessions',
        name: 'web-settings-fail-sessions',
        component: () => import('@/views/admin/security/fail-sessions/TableFailSessions.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_security.fail_sessions',
        }
    },
    /* packages */
    {
        path: '/web-settings/procedure-packages',
        name: 'web-settings-procedure-packages',
        component: () => import('@/views/admin/procedure-packages/TablePackage.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_security.fail_sessions',
        }
    },
    {
        path: '/web-settings/procedure-packages-membership',
        name: 'web-settings-procedure-packages-membership',
        component: () => import('@/views/admin/procedure-packages/memberships/TablePackageMembership.vue'),
        meta: {
            action: 'index',
            resource: 'module_system_settings.settings.admin_security.fail_sessions',
        }
    },
]