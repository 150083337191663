export default [
    {
        path: '/report/admin-data',
        name: 'report-admin-data',
        component: () => import('@/views/admin/admin-data/admin-data/TableAdminData.vue'),
        meta: {
            action: 'index',
            resource: 'module_data_and_reports.admin_data',
        }
    },
    {
        path: '/report/bonus-data',
        name: 'report-bonus-data',
        component: () => import('@/views/admin/admin-data/bonus-data/TableBonusData.vue'),
        meta: {
            action: 'index',
            resource: 'module_data_and_reports.admin_data',
        }
    },
    {
        path: '/report/semaglutide',
        name: 'report-semaglutide',
        component: () => import('@/views/admin/admin-data/semaglutide-report/TableSemaglutideReport.vue'),
        meta: {
            action: 'index',
            resource: 'module_data_and_reports.admin_data',
        }
    },
    {
        path: '/report/rcm',
        name: 'report-rcm',
        component: () => import('@/views/admin/rcm/appointment-review/TableAppointment.vue'),
        meta: {
            action: 'index',
            resource: 'module_data_and_reports.rcm',
        }
    },
    {
        path: '/report/procedure-data',
        name: 'report-procedure-data',
        component: () => import('@/views/admin/admin-data/procedure-data/TableProcedureData.vue'),
        meta: {
            action: 'index',
            resource: 'module_data_and_reports.admin_data',
        }
    },
]